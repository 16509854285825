import { isArray } from 'lodash';

import OwnershipRow from './OwnershipRow';

const Ownership = props => {
  const { formikProps, ownershipPermission, EDIT, VALIDATION_REQUIRED_OWNERSHIP } = props;
  const { errors, values, setFieldValue } = formikProps;
  const handleAddOwnership = () => {
    setFieldValue('ownership', [
      ...values.ownership,
      {
        id: values.ownership.length + 1,
        ownership_customer_first_name: '',
        ownership_customer_phone: '',
        ownership_customer_email: '',
        ownership_customer_pan: '',
        document_type: '',
        ownership_customer_aadhar: '',
        ownership_customer_dl: '',
        ownership_customer_election: '',
        ownership_customer_passport: '',
        ownership_customer_address: '',
        ownership_customer_dob: '',
        ownership_son_of: '',
      },
    ]);
  };
  const handleResetFieldsForDocumentType = (updatedOwnerships, index, documentType) => {
    switch (documentType) {
      case 'Passport':
        updatedOwnerships[index]['ownership_customer_election'] = '';
        updatedOwnerships[index]['ownership_customer_dl'] = '';
        updatedOwnerships[index]['ownership_customer_aadhar'] = '';
        break;
      case 'Aadhar Card':
        updatedOwnerships[index]['ownership_customer_passport'] = '';
        updatedOwnerships[index]['ownership_customer_dl'] = '';
        updatedOwnerships[index]['ownership_customer_election'] = '';
        break;
      case 'Election Card':
        updatedOwnerships[index]['ownership_customer_passport'] = '';
        updatedOwnerships[index]['ownership_customer_dl'] = '';
        updatedOwnerships[index]['ownership_customer_aadhar'] = '';
        break;
      case 'Driving License':
        updatedOwnerships[index]['ownership_customer_passport'] = '';
        updatedOwnerships[index]['ownership_customer_election'] = '';
        updatedOwnerships[index]['ownership_customer_aadhar'] = '';
        break;
      default:
        break;
    }
  };
  const handleUpdateOwnershipData = (index: number, field: string, value) => {
    // const updatedOwnerships = [...values.ownership];

    const updatedOwnerships = values.ownership.map((ownership, i) =>
      i === index ? { ...ownership, [field]: value } : ownership,
    );

    if (field === 'document_type') {
      handleResetFieldsForDocumentType(updatedOwnerships, index, value);
    }
    // updatedOwnerships[index][field] = value;
    setFieldValue('ownership', updatedOwnerships);
  };

  const handleRemoveOwnership = (index: number) => {
    const updatedOwnerships = [...values.ownership];
    updatedOwnerships.splice(index, 1);
    setFieldValue('ownership', updatedOwnerships);
  };

  return (
    <div
      className="booking-form-box shwan-form mt-4"
      style={{
        border: errors.ownership && !isArray(errors.ownership) && '1.2px solid rgba(255, 93, 93) ',
      }}
    >
      <div className="booking-form-col-12">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className={VALIDATION_REQUIRED_OWNERSHIP ? 'mandate-star' : ''}>Ownership Details</h5>

          {ownershipPermission?.add ||
          ownershipPermission?.editor ||
          ownershipPermission?.approval ||
          ownershipPermission?.admin ? (
            <button
              className="Btn btn-lightblue-primary lbps-btn mr-0"
              type="button"
              onClick={handleAddOwnership}
            >
              <div className="d-flex align-items-center justify-content-center">
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span style={{ marginTop: 3, marginLeft: 5 }}> Add Owner</span>
              </div>
            </button>
          ) : undefined}
        </div>
        {values?.ownership?.length ? (
          <>
            {values.ownership?.map((owner, index) => (
              <OwnershipRow
                key={index}
                EDIT={EDIT}
                formikProps={formikProps}
                handleRemoveOwnership={handleRemoveOwnership}
                handleUpdateOwnershipData={handleUpdateOwnershipData}
                index={index}
                owner={owner}
              />
            ))}
          </>
        ) : undefined}
      </div>
    </div>
  );
};

export default Ownership;
